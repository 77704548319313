import React  from "react"
import { graphql, Link } from "gatsby"

export default function Price({ data }) {
  const { prices } = data.price

  return (
    <div>
      <h1>Оллспортс Размеры лицензионных вознаграждений</h1>

      {prices.map((price) => (
        <article key={price.id}>
          <Link state={{ dropdownLink: true }} to={price.fields.slug}>
            <h2>{price.frontmatter.title}</h2>
          </Link>
          <small>{price.frontmatter.date}</small>
          <p>{price.excerpt}</p>
        </article>
      ))}
    </div>
  )
}

export const pageQuery = graphql`
  query PricesQuery {
    price: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(price)/" } }
    ) {
      prices: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
        }
        excerpt
        id
      }
    }
  }
`
